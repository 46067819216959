import { useEffect, useState } from "react";
import {
	BrowserRouter,
	HashRouter,
	useNavigate,
	useRoutes,
} from "react-router-dom";
import Routes from "./routes/Routes";
import { useAuth } from "./hooks/useAuth";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getAnalytics } from "firebase/analytics";
import auth from "./services/auth";
import { ThemeProvider } from "./components/theme-provider";
import { MissionContext } from "./hooks/useMission";
import { DialogProvider } from "./components/dialog-context";
// import { Web3ModalProvider } from "./components/wallet-context";
const projectId = "c0973f2b6020eb8492c58e4115852c02";
import { Toaster } from "@/components/ui/toaster";
import NotMobile from "./NotMobileView";
import "./cloudMessage";
import { toast } from "./hooks/use-toast";
import socket from "./services/socket";

const firebaseConfig = {
	apiKey: "AIzaSyDQ-2sxodmuqY7bb_AG0xF6Ehy_-z-v470",
	authDomain: "supercharge-7452e.firebaseapp.com",
	projectId: "supercharge-7452e",
	storageBucket: "supercharge-7452e.firebasestorage.app",
	messagingSenderId: "270343597800",
	appId: "1:270343597800:web:3393bed999e9c5892ee35d",
	measurementId: "G-3G31P0QTN8",
};

function App() {
	// if (window.innerWidth > 640) {
	// 	return <NotMobile />;
	// }
	const { user } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const app = initializeApp(firebaseConfig);
		socket.on("haveMessage", (data) => {
			console.log(data);
			toast({
				title: data.user_name + " sent you a message",
				description: data.content,
				onClick: () => {
					navigate("/user/chat/" + data.conversation_id);
				},
			});
		});
	}, []);

	if (user) {
		auth.setAuthorizationToken(user?.sctoken);
	}

	let routing = useRoutes(Routes.getRoutes());

	return (
		<div className="flex items-center justify-center h-screen">
			<main className="block md:hidden w-full h-full">
				<Toaster />
				<DialogProvider>
					<ThemeProvider
						defaultTheme="system"
						storageKey="spc-ui-theme">
						<MissionContext.Provider value={{}}>
							{routing}
						</MissionContext.Provider>
						<Toaster />
					</ThemeProvider>
				</DialogProvider>
			</main>
			{/* Other Device View */}
			<div className="w-full bg-foreground text-background flex flex-col items-center justify-center h-full p-16 hidden md:flex">
				<NotMobile />
			</div>
		</div>
	);
}

export default App;
